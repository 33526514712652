<template>
    <div class="flex flex-wrap">
        <EntryTitle
            id="title"
            v-model="entry.title"
            class="w-full mb-4"
        />

        <EntryContent
            label="Content"
            class="w-full mb-2"
            id="content"
            v-model="entry.content"
        />

        <div
            v-if="nextSteps"
            class="border-t mt-2 pt-2 w-full flex items-start flex-wrap"
        >
            <p class="w-full mb-4">Next Steps helper text goes here.</p>
            <label class="mb-2 flex flex-col mr-4">
                <span class="font-bold">Steps Label</span>
                <input
                    class="rounded"
                    v-model="nextSteps.label"
                    placeholder="Next Steps"
                />
            </label>

            <label class="mb-2 flex flex-col mr-4">
                <span class="font-bold">Text</span>
                <input
                    class="rounded"
                    v-model="nextSteps.text"
                    placeholder="Link text"
                />
            </label>

            <label class="flex flex-col">
                <span class="font-bold">URL</span>
                <input
                    class="rounded"
                    v-model="nextSteps.url"
                    type="url"
                    placeholder="https://thestacks.marriott.com"
                />
            </label>
        </div>

        <div
            v-if="keyContact"
            class="border-t mt-2 pt-2 w-full flex items-start flex-wrap"
        >
            <p class="w-full mb-4">Key contact helper text goes here.</p>

            <label class="mb-2 flex flex-col mr-4">
                <span class="font-bold">Title</span>
                <input
                    class="rounded"
                    v-model="keyContact.title"
                    placeholder="Key Contact"
                />
            </label>

            <label class="mb-2 flex flex-col mr-4">
                <span class="font-bold">Name</span>
                <input
                    class="rounded"
                    v-model="keyContact.name"
                    placeholder="Who should be contacted?"
                />
            </label>

            <label class="flex flex-col">
                <span class="font-bold">Email</span>
                <input
                    class="rounded"
                    v-model="keyContact.email"
                    type="email"
                    placeholder="What's their email?"
                />
            </label>
        </div>
    </div>
</template>

<script>
import EntryContent from 'Components/Entries/EntryFields/EntryContent'
import EntryTitle from 'Components/Entries/EntryFields/EntryTitle'

export default {
    components: {
        EntryContent,
        EntryTitle,
    },
    props: {
        value: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {}
    },
    computed: {
        entry: {
            get() {
                return this.value
            },
        },
        nextSteps() {
            if (this.entry?.extra?.nextSteps) {
                return this.entry.extra.nextSteps
            }

            return {
                label: null,
                text: null,
                url: null,
            }
        },
        keyContact() {
            if (this.entry?.extra?.keyContact) {
                return this.entry.extra.keyContact
            }

            return {
                title: null,
                name: null,
                email: null,
                image: null,
                user_id: null,
            }
        },
    },

    watch: {
        entry: {
            handler(newValue, oldvalue) {
                this.$emit('input', newValue)
            },
            deep: true,
        },
    },
    created() {
        if (Array.isArray(this.value?.extra)) {
            this.value.extra = {}
        }
    },
}
</script>
