var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"relative h-7 w-7 rounded-full shadow transition-colors overflow-hidden",on:{"mouseenter":_vm.handleMouseOver,"mouseleave":_vm.handleMouseLeave,"click":_vm.toggleFavorite}},[_c('div',{staticClass:"absolute inset-0 bg-white h-full w-full rounded-full flex items-center justify-center transition-opacity",class:{ 'opacity-0': _vm.isHovered }},[_c('BaseIcon',{staticClass:"transition-transform duration-300 ease-in-out transform",class:{
                'translate-y-0': !_vm.isHovered,
                '-translate-y-full': _vm.isHovered,
            },attrs:{"svg":_vm.bookmarkIcon,"size":"w-[28px] h-[28px]"}})],1),_vm._v(" "),_c('div',{staticClass:"absolute inset-0 h-full w-full rounded-full flex items-center justify-center transition-opacity",class:[
            _vm.hoverClasses,
            { 'opacity-100': _vm.isHovered, 'opacity-0': !_vm.isHovered },
        ]},[_c('BaseIcon',{staticClass:"transition-transform duration-300 ease-in-out transform",class:{
                'translate-y-full': !_vm.isHovered,
                'translate-y-0': _vm.isHovered,
            },attrs:{"svg":_vm.hoverIcon,"size":"w-[28px] h-[28px]"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }