var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseButton',{class:[
        'rounded-full',
        'bg-blue',
        'text-white',
        'font-medium',
        'w-full',
        'md:w-auto',
        'text-center',
        'no-underline',
        'inline-block',
        'hover:text-white',
        'hover:bg-blue-medium',
        'transition-colors',
        'text-base',
        _vm.padding,
    ],attrs:{"href":_vm.href},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }