<template>
    <div
        :id="`searchInput--${id}`"
        class="relative text-sm row-span-2 grid grid-flow-row grid-row-subgrid gap-4"
        @focus="viewOptions = true">
        <div :class="borderClasses">
            <div
                v-if="viewOptions"
                class="searchInput--blocker"
                @click="hideOptions()" />
            <label
                :class="labelClasses"
                :for="id"
                @click="showOptions()">
                <span>{{ label }}</span>
                <span
                    v-if="hasOptionalLabel"
                    :class="optionalLabelClasses"
                    class="italic font-swiss text-base font-normal">
                    {{ optionalLabel }}
                </span>
            </label>

            <div
                :class="[
                    'searchInput--optionsWrapper',
                    viewOptions ? 'drop-shadow-bottom' : '',
                ]">
                <div
                    class="relative w-full"
                    @click="showOptions()">
                    <input
                        :id="id"
                        ref="filterInput"
                        v-model="filter"
                        type="text"
                        :class="inputClasses"
                        :placeholder="placeholder"
                        @focusin="showOptions()"
                        @input="liveSearch ? searchOptions() : null" />
                    <!-- Separate: SVG Plus Icon -->
                    <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="absolute right-4 top-1/2 -translate-y-1/2 w-3 h-3">
                        <path
                            d="M10.625 5.5C10.625 5.82812 10.3672 6.0625 10.0625 6.0625H6.3125V9.8125C6.3125 10.1406 6.05469 10.3984 5.75 10.3984C5.42188 10.3984 5.1875 10.1406 5.1875 9.8125V6.0625H1.4375C1.10938 6.0625 0.875 5.82812 0.875 5.52344C0.875 5.19531 1.10938 4.9375 1.4375 4.9375H5.1875V1.1875C5.1875 0.882812 5.42188 0.648438 5.75 0.648438C6.05469 0.648438 6.3125 0.882812 6.3125 1.1875V4.9375H10.0625C10.3672 4.9375 10.625 5.19531 10.625 5.5Z"
                            fill="currentColor" />
                    </svg>

                    <i
                        v-if="isLoading"
                        class="searchInput--loading far fa-spinner fa-spin fa-lg fa-fw" />
                </div>

                <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-class="transform scale-95 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-95 opacity-0">
                    <ul
                        v-if="viewOptions"
                        class="searchInput--options">
                        <li
                            v-if="!filter && filteredOptions.length > 1"
                            class="searchInput--option">
                            <span @click="toggleSelected()">
                                All {{ labelPlural }}
                            </span>
                        </li>

                        <li
                            v-if="liveSearch && !filter && searchHelpText"
                            class="searchInput--option">
                            {{ searchHelpText }}
                        </li>

                        <li
                            v-if="filter && !filteredOptions.length"
                            class="searchInput--option">
                            No {{ labelPlural.toLowerCase() }} were found
                        </li>

                        <li
                            v-for="(option, i) in filteredOptions"
                            :key="i">
                            <label
                                :class="[
                                    'searchInput--option',
                                    isSelected(option) ? '--selected' : '',
                                ]">
                                {{ optionTextTransform(option) }}
                                <input
                                    v-model="selected"
                                    type="checkbox"
                                    class="sr-only"
                                    :value="option" />
                            </label>
                        </li>
                    </ul>
                </transition>
            </div>
        </div>

        <slot
            v-if="selected.length"
            id="tags">
            <span
                v-if="!liveSearch && selected.length === options.length"
                class="text-sm border border-white p-2 rounded text-center selected"
                @click="selected = []">
                All {{ labelPlural }} selected
                <i class="far fa-times" />
            </span>

            <div class="inline-flex gap-2 flex-wrap truncate max-w-full scroll">
                <SearchTagInput
                    v-for="(item, i) in visibleSelected"
                    :key="i"
                    :item="item"
                    :selected="selected"
                    :selected-text-transform="selectedTextTransform"
                    :selected-display-key="selectedDisplayKey"
                    :type="searchType"
                    @update:selected="updateSelected" />
            </div>
        </slot>
    </div>
</template>

<script>
import axios from 'axios'
import { debounce } from 'lodash'
import SearchTagInput from './SearchTagInput.vue'

export default {
    name: 'SearchInput',
    components: {
        SearchTagInput,
    },
    props: {
        id: {
            required: true,
            type: String,
        },
        value: {
            required: true,
            default: () => [],
            type: Array,
        },
        inputClasses: {
            required: false,
            type: String,
            default:
                'w-full border border-white ring-blue rounded-md focus:ring-blue focus:ring focus:outline-none focus:border-0 relative transition-position cursor-pointer bg-gray-darkest placeholder-white text-sm',
        },
        borderClasses: {
            required: false,
            type: String,
            default:
                'border-t border-r border-l border-[#D8DFEF]/20 rounded-md col-span-full',
        },
        label: {
            required: true,
            type: String,
        },
        labelClasses: {
            required: false,
            type: String,
            default: 'text-blue-lightest text-lg lg:text-2xl px-4 py-2 block',
        },
        labelPlural: {
            required: true,
            type: String,
        },
        optionalLabel: {
            required: false,
            type: String,
            default: 'optional',
        },
        optionalLabelClasses: {
            required: false,
            type: String,
            default: '',
        },
        hasOptionalLabel: {
            required: false,
            type: Boolean,
            default: false,
        },
        endpoint: {
            required: false,
            type: String,
            default: null,
        },
        filterBy: {
            required: true,
            type: String,
        },
        searchDisplayKey: {
            required: true,
            type: String,
        },
        selectedDisplayKey: {
            required: true,
            type: String,
        },
        placeholder: {
            required: false,
            type: String,
            default: null,
        },
        liveSearch: {
            required: false,
            default: false,
            type: Boolean,
        },
        searchKey: {
            required: false,
            type: String,
            default: null,
        },
        searchParam: {
            required: false,
            type: String,
            default: null,
        },
        searchType: {
            type: String,
            required: true,
        },
        optionTextTransform: {
            required: false,
            type: Function,
            default(option) {
                return option[this.searchDisplayKey]
            },
        },
        selectedTextTransform: {
            required: false,
            type: Function,
            default(option) {
                return option[this.selectedDisplayKey]
            },
        },
        searchHelpText: {
            required: false,
            type: String,
            default: null,
        },
        showTags: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: [],
            filter: null,
            internalValue: [],
            viewOptions: false,
            isLoading: false,
        }
    },
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(selected) {
                this.internalValue = selected
                this.$emit('input', this.internalValue)
                this.hideOptions()
            },
        },
        filteredOptions() {
            let filtered = this.options

            if (this.liveSearch) {
                return this.options
            }

            if (this.filter) {
                return filtered.filter((option) => {
                    return option[this.filterBy]
                        .toLowerCase()
                        .includes(this.filter.toLowerCase())
                })
            }

            return filtered
        },
        visibleSelected() {
            if (
                !this.liveSearch &&
                this.selected.length === this.options.length
            ) {
                return []
            }

            return this.selected
        },
    },
    mounted() {
        if (this.endpoint && !this.liveSearch) {
            this.fetchOptions()
        }

        this.internalValue = this.value
    },
    methods: {
        fetchOptions() {
            if (this.endpoint) {
                axios
                    .get(this.endpoint)
                    .then((response) => {
                        this.options = response.data.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        searchOptions: debounce(function () {
            if (!this.filter) {
                this.options = []
                return false
            }

            let params = {}

            params[this.searchParam] = this.filter

            this.isLoading = true

            axios
                .get(this.endpoint, { params })
                .then((response) => {
                    this.options = response.data.data
                    this.showOptions()
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.isLoading = false
                })
        }, 300),
        blockerListener(event) {
            if (event.code === 'Escape') {
                this.viewOptions = false
            }
        },
        showOptions() {
            this.viewOptions = true
            document.body.addEventListener('keyup', this.blockerListener)
            this.$refs.filterInput.classList.remove('hidden')
            this.$refs.filterInput.focus()
        },
        hideOptions() {
            this.viewOptions = false
            document.body.removeEventListener('keyup', this.blockerListener)
        },
        isSelected(option) {
            let filterBy = this.filterBy
            return this.selected.find(
                (selected) => selected[filterBy] === option[filterBy]
            )
        },
        toggleSelected() {
            if (this.selected.length === this.options.length) {
                this.selected = []
            } else {
                this.selected = this.options
            }
        },
        updateSelected(newSelected) {
            this.$emit('input', newSelected)
        },
    },
}
</script>

<style scoped>
.scroll {
    max-height: 230px;
    overflow-y: auto;
}

/* Firefox */
.scroll {
    scrollbar-width: auto;
    /* scrollbar-color: #0f358a #ecf0f8; /* Commented out because this adds unwanted padding or something on the track in Chrome that I can't figure out how to remove */
}

/* WebKit-based browsers (Chrome, Safari, Edge) */
.scroll::-webkit-scrollbar {
    width: auto;
}
.scroll::-webkit-scrollbar-track {
    background: #ecf0f8;
}
.scroll::-webkit-scrollbar-thumb {
    background-color: #0f358a;
}
/*.scroll::-webkit-scrollbar-button {
    background-color: #ecf0f8;
    cursor: pointer;
    display: block;
} /* Commented out because the top and bottom buttons are basically empty blocks with no up/down arrows and I can't figure out how to get them back */
</style>
