var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center gap-8"},[_c('div',{staticClass:"flex items-center gap-8"},[_c('ButtonStripped',{staticClass:"text-white font-normal overflow-hidden relative h-[36px] rounded-none",attrs:{"href":"/"},nativeOn:{"mouseenter":function($event){return _vm.handleMouseOver.apply(null, arguments)},"mouseleave":function($event){return _vm.handleMouseLeave.apply(null, arguments)}}},[_c('div',{staticClass:"absolute inset-0 flex items-center justify-center transition-opacity",class:{ 'opacity-0': _vm.isHovered }},[_c('BaseIcon',{staticClass:"transition-transform duration-300 ease-in-out transform",class:{
                        'translate-y-0': !_vm.isHovered,
                        '-translate-y-full': _vm.isHovered,
                    },attrs:{"svg":_vm.stacksIcon,"size":"w-[36px] h-[36px]"}})],1),_vm._v(" "),_c('div',{staticClass:"absolute inset-0 flex items-center justify-center transition-opacity",class:{ 'opacity-100': _vm.isHovered }},[_c('BaseIcon',{staticClass:"transition-transform duration-300 ease-in-out transform",class:{
                        'translate-y-0': _vm.isHovered,
                        'translate-y-[50px]': !_vm.isHovered,
                    },attrs:{"svg":_vm.hoveredHomeIcon,"size":"w-[36px] h-[36px]"}})],1)]),_vm._v(" "),_c('ButtonStripped',{staticClass:"text-white font-normal hidden md:block",attrs:{"padding":"px-0 py-2","text-color":"text-white","href":"/search"}},[_c('BaseIcon',{attrs:{"svg":_vm.searchIcon,"size":"w-[33px] h-[33px]"}})],1),_vm._v(" "),_c('ButtonStripped',{staticClass:"text-white font-normal hidden md:block",attrs:{"padding":"px-0 py-2","text-color":"text-white","href":"/assets"}},[_c('BaseIcon',{attrs:{"svg":_vm.folderIcon,"size":"w-[33px] h-[33px]"}})],1),_vm._v(" "),_c('ButtonStripped',{staticClass:"text-white font-normal hidden md:block",attrs:{"padding":"px-0 py-2","text-color":"text-white","href":"/notifications"}},[_c('BaseIcon',{staticClass:"text-[13px]",attrs:{"svg":_vm.notificationsIcon,"notification":_vm.notificationCount}})],1)],1),_vm._v(" "),_c('NavigationUserFavorites',{staticClass:"hidden md:block",attrs:{"user":_vm.userData,"is-admin":_vm.isAdmin}}),_vm._v(" "),_c('NavigationUserAccount',{attrs:{"user":_vm.userData,"is-admin":_vm.isUserAdmin}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }