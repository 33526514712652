<template>
    <div>
        <ul class="p-4 flex gap-2">
            <li
                v-for="link in links"
                :key="link.url"
                class="relative">
                <a
                    :class="[
                        'flex items-center gap-4 bg-white font-bold rounded-full shadow-sm p-4 cursor-pointer',
                        isActive(link)
                            ? 'text-blue'
                            : 'text-black hover:text-blue',
                    ]"
                    :href="link.url"
                    @mouseover="setHoveredLink(link)"
                    @mouseleave="clearHoverLink"
                    @click="handleClick($event, link)">
                    <i
                        :class="[
                            'text-gray',
                            'far',
                            'fa-fw',
                            `fa-${link.icon}`,
                        ]" />
                </a>
                <BaseTooltip
                    v-if="link.tooltip"
                    :show-tooltip="isHovered(link)"
                    :message="link.tooltip"
                    class="-translate-x-1/2 left-1/2" />
            </li>
        </ul>
    </div>
</template>

<script>
import axios from 'axios'
import BaseTooltip from 'Components/Redesign/Base/BaseTooltip.vue'
import ButtonPrimary from 'Components/Redesign/Button/ButtonPrimary.vue'
import FormInput from 'Components/Redesign/Form/FormInput.vue'
import { mapState } from 'vuex'

export default {
    name: 'LinkNav',
    components: {
        BaseTooltip,
        ButtonPrimary,
        FormInput,
    },
    props: {
        links: {
            required: true,
            type: Array,
        },
        isOpen: {
            required: false,
            default: true,
            type: Boolean,
        },
        workspaceId: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            hoveredLink: null,
            isRenameModalVisible: false,
            newWorkspaceName: '',
        }
    },
    methods: {
        ...mapState({
            workspaces: (state) => state.user.workspaces,
        }),
        isActive(link) {
            let url = new URL(window.location)
            return link.url === url.pathname
        },
        setHoveredLink(link) {
            this.hoveredLink = link
        },
        clearHoverLink() {
            return (this.hoveredLink = null)
        },
        isHovered(link) {
            return this.hoveredLink === link
        },
        capitalize(text) {
            if (!text) return ''
            return text.charAt(0).toUpperCase() + text.slice(1) + ' Workspace'
        },
        handleClick(event, link) {
            if (link.action) {
                link.action({ event, link })
            } else if (link.url) {
                window.location.href = link.url
            }
        },
    },
}
</script>
