// export const SEARCH = '/api/structure/search';

// Asset
export const ASSET_UPDATE = '/api/structure/assets'
export const ASSET_UPDATE_VISIBLE_TO_API =
    '/api/structure/assets/api-visibility'
export const DELETE_RIGHTS_DOCS = '/ajax/rights-documents'
export const DOWNLOAD_ASSETS = '/download/multiple'
export const UPLOAD_RIGHTS_DOCS = '/ajax/rights-documents'

// Logo
export const LOGO_UPLOAD_CREATE = '/assets/logos/create'
export const LOGO_UPLOAD_RESET = '/assets/logos/reset'
export const LOGO_UPLOAD_STATUS_CHECK = '/assets/logos/status'
export const LOGO_UPLOAD_UPLOAD_ZIP = '/assets/logos/upload'

// Model Structures
export const ASSET_LIST = '/api/structure/assets'
export const ASSET_STATUSES = '/api/structure/asset-statuses'
export const ASSET_TYPES_LIST = '/api/structure/asset-types'
export const BRANDS_LIST = '/api/structure/brands'
export const COLLECTIONS_LIST = '/api/structure/collections'
export const COLORS_LIST = '/api/structure/colors'
export const CONTAINERS_LIST = '/sitemanager/mdam/containers'
export const COUNTRIES_LIST = '/api/structure/countries'
export const DATAPOINTS_LIST = '/sitemanager/mdam/datapoints'
export const DESTINATION_GROUPS = '/api/structure/destination-groups'
export const DIVISIONS_LIST = '/api/structure/divisions'
export const LANGUAGES_LIST = '/api/structure/languages'
export const OWNERSHIP_COMPANIES_LIST = '/api/structure/ownership_companies'
export const PROPERTIES_LIST = '/api/structure/properties'
export const PROPERTY_TYPES = '/api/structure/property-types'
export const REGIONS_LIST = '/api/structure/regions'
export const ROOM_CODES_LIST = '/api/structure/room-codes'
export const SOURCE_TYPES = '/api/structure/source-types'
export const STATES_LIST = '/api/structure/states'
export const SUBJECTS_LIST = '/api/structure/subjects'
export const TEMPLATE_TYPES_LIST = '/api/structure/templates/types'
export const TEMPLATE_TYPE_CATEGORIES_LIST = '/api/structure/templates/categories'
export const TIME_ZONES_LIST = '/api/structure/time-zones'
export const USER_LOOKUP = '/sitemanager/users/lookup'
export const USER_SEARCH = '/sitemanager/users/search'
export const VENDOR_TYPES = '/api/structure/vendor-types'

// Remediations
export const REMEDIATIONS_SUBMIT = '/work-orders/remediations/create'
export const REMOVE_RIGHTS_DOCS = '/ajax/rights-documents/remove'

// Search
export const SEARCH = '/api/search'
export const EMBEDDED_SEARCH = '/api/embedded/search'
export const ADD_STORED_SEARCH =
    '/sitemanager/assets/stored_searches?action=add'
export const SEARCHES_RECENT_LIST = '/api/structure/searches/recent'
export const SEARCHES_STORED_LIST = '/api/structure/searches/stored'

// User Account
export const USER_GET_ACCOUNT = '/api/account'
export const USER_ASSET_TYPES_LIST = '/api/structure/user/asset-types'
export const USER_ASSOCIATIONS = '/api/structure/user/associations'
export const USER_ASSOCIATIONS_LIST = '/api/structure/user/associations'
export const USER_BRANDS_LIST = '/api/structure/user/brands'
export const USER_GROUPS_LIST = '/api/structure/user-groups'
export const USER_PROPERTIES_LIST = '/api/structure/user/properties'
export const USER_REGIONS_LIST = '/api/structure/user/regions'
export const USER_SELECTED_ASSETS = '/api/structure/user/selected-assets'
export const USER_USER_GROUPS_LIST = '/api/structure/user/groups'
export const USER_WORK_ORDERS_LIST = '/api/structure/user/work-orders'

// Content Endpoints
export const ENTRIES_LIST = '/api/content/entries'
export const ENTRIES_CREATE = '/api/content/entries'
export const ENTRIES_UPDATE = '/api/content/entries'
export const ENTRIES_DELETE = '/api/content/entries'

// Contact Form Endpoints
export const CONTACT_FORM_SUBMIT = '/api/contact'

// Notification Endpoints
export const SYSTEM_MESSAGES_LIST = '/api/alerts'
export const ACCOUNT_LIST_ASSET_NOTIFICATIONS = '/api/account/notifications/assets'
export const ACCOUNT_LIST_UPDATE_NOTIFICATIONS = '/api/account/notifications/updates'
export const ACCOUNT_LIST_DEACTIVATIONS = '/api/account/deactivations'
export const ACCOUNT_LIST_DOWNLOADS = '/api/account/downloads'
export const ACCOUNT_UPDATE_ASSET_NOTIFICATIONS = '/api/account/notifications/assets'

// Account Settings Endpoints
export const USER_ACCOUNT_SETTINGS = '/api/account/settings'

// Workspaces Endpoints
export const USER_WORKSPACES_CREATE = '/api/account/workspaces'
export const USER_WORKSPACES_LIST = '/api/account/workspaces'
export const USER_WORKSPACES_UPDATE = '/api/account/workspaces'

// Favorites Endpoints
export const USER_FAVORITES_LIST = '/api/account/favorites'
export const USER_FAVORITES_CREATE = '/api/account/favorites'
export const USER_FAVORITES_UPDATE = '/api/account/favorites'
export const USER_FAVORITES_DELETE = '/api/account/favorites'
export const USER_FAVORITES_BULK_UPDATE = '/api/account/favorites'

// Guidelines & Collateral
export const GUIDELINES_LIST = '/api/guidelines'
export const COLLATERAL_LIST = '/api/collateral'

export default {
    ACCOUNT_LIST_ASSET_NOTIFICATIONS,
    ACCOUNT_LIST_DEACTIVATIONS,
    ACCOUNT_LIST_DOWNLOADS,
    ACCOUNT_LIST_ASSET_NOTIFICATIONS,
    ACCOUNT_UPDATE_ASSET_NOTIFICATIONS,
    ADD_STORED_SEARCH,
    ASSET_LIST,
    ASSET_STATUSES,
    ASSET_TYPES_LIST,
    ASSET_UPDATE,
    ASSET_UPDATE_VISIBLE_TO_API,
    BRANDS_LIST,
    COLLATERAL_LIST,
    COLLECTIONS_LIST,
    COLORS_LIST,
    CONTACT_FORM_SUBMIT,
    CONTAINERS_LIST,
    COUNTRIES_LIST,
    DATAPOINTS_LIST,
    DELETE_RIGHTS_DOCS,
    DESTINATION_GROUPS,
    DIVISIONS_LIST,
    DOWNLOAD_ASSETS,
    ENTRIES_CREATE,
    ENTRIES_DELETE,
    ENTRIES_LIST,
    ENTRIES_UPDATE,
    GUIDELINES_LIST,
    LANGUAGES_LIST,
    LOGO_UPLOAD_CREATE,
    LOGO_UPLOAD_RESET,
    LOGO_UPLOAD_STATUS_CHECK,
    LOGO_UPLOAD_UPLOAD_ZIP,
    OWNERSHIP_COMPANIES_LIST,
    PROPERTIES_LIST,
    PROPERTY_TYPES,
    REGIONS_LIST,
    REMEDIATIONS_SUBMIT,
    ROOM_CODES_LIST,
    SEARCH,
    EMBEDDED_SEARCH,
    SEARCHES_RECENT_LIST,
    SEARCHES_STORED_LIST,
    SOURCE_TYPES,
    STATES_LIST,
    SUBJECTS_LIST,
    SYSTEM_MESSAGES_LIST,
    TEMPLATE_TYPES_LIST,
    TEMPLATE_TYPE_CATEGORIES_LIST,
    TIME_ZONES_LIST,
    UPLOAD_RIGHTS_DOCS,
    USER_ACCOUNT_SETTINGS,
    USER_ASSET_TYPES_LIST,
    USER_ASSOCIATIONS,
    USER_ASSOCIATIONS_LIST,
    USER_BRANDS_LIST,
    USER_FAVORITES_BULK_UPDATE,
    USER_FAVORITES_CREATE,
    USER_FAVORITES_DELETE,
    USER_FAVORITES_LIST,
    USER_FAVORITES_UPDATE,
    USER_GET_ACCOUNT,
    USER_GROUPS_LIST,
    USER_LOOKUP,
    USER_PROPERTIES_LIST,
    USER_REGIONS_LIST,
    USER_SEARCH,
    USER_SELECTED_ASSETS,
    USER_USER_GROUPS_LIST,
    USER_WORKSPACES_CREATE,
    USER_WORKSPACES_LIST,
    USER_WORK_ORDERS_LIST,
    VENDOR_TYPES,
}
