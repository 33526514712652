var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        _vm.block,
        _vm.isOpen ? _vm.bgColorOpened : _vm.bgColorClosed,
        'py-2 pl-6 pr-3 rounded',
    ]},[_c('header',[_c('div',{class:`${_vm.block}__${_vm.headerElement}`,attrs:{"id":`${_vm.id}-control`,"aria-expanded":`${_vm.isOpen}`,"aria-controls":_vm.id,"tabindex":"0","role":"button"},on:{"click":_vm.open,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.open.apply(null, arguments)}}},[_vm._t("heading"),_vm._v(" "),_vm._t("icon",function(){return [_c('svg',{class:[
                        _vm.isOpen
                            ? 'rotate-180 text-blue bg-blue-lighter'
                            : 'text-white bg-blue',
                        'rounded-full',
                    ],attrs:{"width":"48","height":"48","viewBox":"0 0 48 48","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"48","height":"48","fill":_vm.isOpen ? 'bg-blue-light' : 'bg-blue'}}),_vm._v(" "),_c('path',{attrs:{"d":"M16 22L24 30L32 22","stroke":_vm.isOpen ? 'currentColor' : 'white',"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]})],2)]),_vm._v(" "),_c('transition',{attrs:{"name":"accordion__transition"},on:{"enter":_vm.startTransition,"after-enter":_vm.endTransition,"before-leave":_vm.startTransition,"after-leave":_vm.endTransition}},[(_vm.isOpen)?_c('div',{ref:"content",class:`${_vm.block}__${_vm.contentElement}`,attrs:{"id":_vm.id,"aria-labelledby":`${_vm.id}-control`}},[_vm._t("default")],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }