var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"bg-blue-darkest rounded-md p-4"},[_vm._l((_vm.items),function(links,section){return _c('div',[_c('span',{staticClass:"text-gray-200 font-bold inline-block w-full text-sm",domProps:{"textContent":_vm._s(section)}}),_vm._v(" "),_c('ul',{staticClass:"inline-block w-full mb-4"},_vm._l((links),function(link,i){return _c('li',[(link.routerLink)?_c('router-link',{staticClass:"inline-block rounded text-blue-lighter py-2 hover:underline",attrs:{"to":link.routerLink}},[(link.icon)?_c('i',{class:[
                            'inlin-block',
                            'mr-2',
                            'far',
                            'fa-fw',
                            link.icon,
                        ]}):_vm._e(),_c('span',{staticClass:"w-full",domProps:{"textContent":_vm._s(link.text)}}),_vm._v(" "),(link.help)?_c('span',{staticClass:"text-xs italic inline-block ml-6",domProps:{"textContent":_vm._s(link.help)}}):_vm._e()]):_vm._e()],1)}),0)])}),_vm._v(" "),_vm._m(0)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"inline-block text-sm rounded bg-coral px-4 py-2 mb-2 text-white font-bold hover:underline w-full",attrs:{"href":"/sitemanager"}},[_c('i',{staticClass:"far fa-fw fa-chevron-left inline-block mr-2"}),_vm._v("\n        Back to SiteManager\n    ")])
}]

export { render, staticRenderFns }