<template>
	<div v-if="display" class="rounded-full bg-blue-light shadow border border-blue-medium p-1 list-format-toggle">
        <span :class="{'inactive':!displayColumns}">
            <i @click="formatAs('columns')" class="far fa-th"/>
        </span>
        <span :class="{'inactive':!displayCarousel}">
            <i @click="formatAs('carousel')" class="far fa-presentation"/>
        </span>
    </div>
</template>
<script>

export default {
    components: {
    },
    props: {
        display: {
            required: false,
            default: true,
            type: Boolean,
        }, 
        format: {
            required: false,
            default: 'columns',
            type: String,
        }
    },
    data() {
        return {
            displayFormat: 'columns',
        }
    },
    computed: {
        displayColumns() {
            return this.displayFormat == 'columns';
        },
        displayCarousel() {
            return this.displayFormat == 'carousel';
        },
    },
    methods: {
        formatAs(format) {
            this.displayFormat = format;
            this.$emit('change', format);
        }
    },
    mounted() {
        this.displayFormat = this.format;
    }
}
</script>
<style scoped>
.list-format-toggle i {
    color:#0b4285;
    cursor: pointer;
    line-height: 1.9rem;
}
.list-format-toggle span {
    background-color: #FFF;
    border-radius: 50%;
    display: inline-block;
    height: 1.9rem;
    text-align: center;
    width: 1.9rem;
}
.list-format-toggle span.inactive {
    background-color: transparent;
}
.list-format-toggle i:hover {
    color: #2879dd;
}
</style>
