var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('hr',{staticClass:"my-5 mt-8 -mx-8 border-t-0 border-b-2 border-gray-200"}),_vm._v(" "),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"w-2/3 flex items-center gap-4"},[_c('div',{staticClass:"mr-6"},[_c('RadioTab',{attrs:{"name":'displayTab',"options":[
                        {
                            value: 'by-collection',
                            label: 'View by Collection',
                        },
                        { value: 'asset-list', label: 'View All Assets' },
                    ]},on:{"input":_vm.toggleDisplay},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}})],1),_vm._v(" "),(_vm.byAssetList)?_c('div',{staticClass:"mr-3 w-1/3"},[_c('AccountFormSelect',{staticClass:"!text-sm !mt-1 !border-blue-medium",attrs:{"name":'asset_type_id',"options":_vm.assetTypes,"input-label":"Asset Type","placeholder":"Show All Asset Types","label":"name","reduce":(option) => option.id,"required":false},model:{value:(_vm.assetTypeId),callback:function ($$v) {_vm.assetTypeId=$$v},expression:"assetTypeId"}})],1):_vm._e()]),_vm._v(" "),(_vm.byAssetList)?_c('div',{staticClass:"w-1/3"},[_c('div',{staticClass:"keyword w-full relative"},[_c('FormInput',{attrs:{"type":"text","label":"Filter assets by keyword"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_vm._v(" "),_c('div',{staticClass:"clear-link absolute w-auto right-4 top-5"},[(_vm.keyword)?_c('a',{staticClass:"cursor-pointer text-coral-dark text-sm",on:{"click":_vm.clear}},[_vm._v("\n                        clear\n                    ")]):_vm._e()])],1)]):_vm._e()]),_vm._v(" "),_c('hr',{staticClass:"mt-5 mb-8 -mx-8 border-t-0 border-b-2 border-gray-200"}),_vm._v(" "),(_vm.byAssetList)?_c('asset-list',{attrs:{"comms-page-id":_vm.commsPageId,"keyword":_vm.keyword,"asset-type-id":_vm.assetTypeId,"collection-ids":_vm.collectionIds,"is-admin":_vm.isAdmin}}):_vm._e(),_vm._v(" "),(_vm.byCollection)?_c('by-collection',{attrs:{"comms-page-id":_vm.commsPageId,"collections":_vm.collections,"current-hash":_vm.initialHash,"stats":_vm.stats,"keyword":_vm.keyword,"asset-type-id":_vm.assetTypeId,"is-admin":_vm.isAdmin,"download-limit":_vm.downloadLimit},on:{"open":_vm.pushHistory}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }