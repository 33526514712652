var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
        'px-2',
        'py-1',
        'text-xs',
        { 'bg-blue-dark': _vm.isActive },
        { 'text-white': _vm.isActive },
    ],on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.icon)?_c('i',{class:['far', 'fa-fw', _vm.icon]}):_vm._e(),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }