<template>
    <vue-flux
        :options="options"
        :images="images"
        :transitions="transitions"
        :captions="links">

        <template v-slot:caption>
            <flux-caption v-slot="captionProps" v-if="showCaptions">
                <a href="#" @click.prevent="deselectAsset(captionProps)" v-if="isSelected(captionProps)">
                    Deselect
                </a>
                <a href="#" @click.prevent="selectAsset(captionProps)" v-else>
                    Select
                </a> | 
                <a :href="parseCaptionText(captionProps)">
                    View Details
                </a>
            </flux-caption>
        </template>

        <template v-slot:preloader>
            <div class="text-center my-12" v-if="displayNoImagesMessage">
                <span class="bg-black bg-opacity-80 text-lg text-blue-lightest px-2 py-1">
                    There are no images to display
                </span>
            </div>
            <flux-preloader v-else>
                <div class="fa-3x">
                    <i class="fas fa-spinner fa-spin"></i>
                </div>
            </flux-preloader>
        </template>
        
        <template v-slot:controls>
            <flux-controls />
        </template>

        <template v-slot:index>
            <flux-index />
            <div class="text-center mb-1" v-if="displayImagesOnlyMessage">
                <span class="bg-black bg-opacity-80 text-xs text-blue-lightest px-2 py-1">
                    Displaying Images Only
                </span>
            </div>
        </template>
    </vue-flux>
</template>
<script>
import {
	Img,
	FluxPreloader,
	FluxCaption,
	FluxControls,
	FluxIndex,
	VueFlux,
} from 'vue-flux';

import { isImageUrl } from 'Composables/useAssets.js'

export default {
    components: {
        Img,
		FluxPreloader,
		FluxCaption,
		FluxControls,
		FluxIndex,
		VueFlux,
    },
    props: {
        isGuest: {
            required: false,
            type: Boolean,
            default: false,
        },
        assets: {
            required: false,
            default: () => [],
            type: Array,
        },
        selected: {
            required: false,
            default: () => [],
            type: Array,
        },
    },
    data: () => ({
        options: {
            autohideTime: 0,
            autoplay: false,
            delay: 4000,
            totalDuration:100,
        },
        transitions: [
            {
                name: 'fade',
                options: {
                    totalDuration: 100
                }
            }
        ]
    }),
    computed: {
        imageAssets() {
            // Filter out non-image assets
            return this.assets.filter((a) => {
                if (!a.asset_thumbnail_url && !a.asset_low_url) {
                    return false;
                }
                if (isImageUrl(a.asset_low_url)) {
                    return true;
                }
                return false;
            });
        },
        images() {
            return this.imageAssets.map(a => a.asset_low_url ? a.asset_low_url : a.asset_thumbnail_url);
        },
        links(){
            return this.imageAssets.map(a => a.links.data.view);
        },
        showCaptions() {
            return !this.isGuest;
        },
        displayNoImagesMessage() {
            return this.imageAssets.length == 0;
        },
        displayImagesOnlyMessage() {
            if (this.displayNoImagesMessage) {
                return false;
            }
            return this.assets.length > this.imageAssets.length;
        }
    },
    methods: {
        parseCaptionText(caption) {
            return caption.text;
        },
        isSelected(caption) {
            const assetId = this.parseAssetId(caption);

            if (assetId) {
                let selected = this.selected.filter((a) => {
                    return a.asset_id === assetId
                });

                if (selected.length > 0) {
                    return true;
                }
            }
            
            return false;
        },
        selectAsset(caption) {
            const asset = this.getAsset(caption);

            if (asset) {
                this.$emit('toggleSelected', true, asset);
            }
        },
        deselectAsset(caption) {
            const asset = this.getAsset(caption);

            if (asset) {
                this.$emit('toggleSelected', false, asset);
            }
        },
        getAsset(caption) {
            const assetId = this.parseAssetId(caption);
            
            const matched = this.assets.filter((a) => {
               return (a.asset_id === assetId);
            });

            if (matched.length) {
                return matched[0];
            }

            return null;
        },
        parseAssetId(caption) {
            const match = caption.text.match(/asset_id=(\d+)$/);

            if (match) {
                return parseInt(match[1], 10);
            }

            return null;
        }
    },
}
</script>
<style>
    /* div.flux-image {
        width:100% !important; /* Not sure why I did this originally. Commented out because it breaks the tile/index display
    } */
    .vue-flux .flux-index ul {
        background-color:#FFF;
    }
    .vue-flux .flux-caption {
        background-color:rgb(0, 16, 51);
        font-size: 1.1rem;
        font-weight: 700;
    }
    .vue-flux .flux-caption a {
        color: #FFF;
    }
    .flux-controls button.play,
    .flux-controls button.pause {
        display: none;
    }
    .flux-button svg circle {
        fill:rgb(0, 16, 51);
    }
</style>
