var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseButton',{class:[
        'rounded-full',
        'bg-blue-lighter',
        'border',
        'border-blue-lighter',
        'hover:bg-white',
        'font-bold',
        'md:w-auto',
        'text-center',
        'cursor-pointer',
        'no-underline',
        'text-black',
        'hover:border',
        'hover:border-coral',
        'transition-colors',
        _vm.textSize,
        _vm.padding,
    ],attrs:{"href":_vm.href},on:{"mouseenter":_vm.mouseEnter,"mouseleave":_vm.mouseLeave,"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }