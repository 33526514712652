<template>
    <div
        :id="`searchInput--${id}`"
        class="relative text-sm row-span-2 grid grid-flow-row grid-row-subgrid gap-4">
        <div class="border border-[#D8DFEF]/20 rounded-md">
            <div
                v-if="viewOptions"
                class="searchInput--blocker"
                @click="hideOptions()" />

            <label
                class="text-blue-lightest text-lg lg:text-2xl px-4 py-2 block"
                :for="id"
                @click="showOptions()">
                <span>{{ label }}</span>
            </label>

            <div
                :class="[
                    'searchInput--optionsWrapper',
                    viewOptions ? 'drop-shadow-bottom' : '',
                ]">
                <div
                    class="relative w-full"
                    @click="showOptions()">
                    <input
                        :id="id"
                        ref="filterInput"
                        v-model="filter"
                        type="text"
                        :class="[
                            'w-full border ring-blue focus:ring-blue focus:ring focus:outline-none focus:border-0 relative transition-position cursor-pointer',
                            inputClasses,
                        ]"
                        :placeholder="placeholder"
                        @focusin="showOptions()"
                        @keyup.enter="addKeyword(filter)" />
                    <svg
                        v-if="showIcon"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="absolute right-4 top-1/2 -translate-y-1/2 w-3 h-3 fill-white">
                        <path
                            d="M10.625 5.5C10.625 5.82812 10.3672 6.0625 10.0625 6.0625H6.3125V9.8125C6.3125 10.1406 6.05469 10.3984 5.75 10.3984C5.42188 10.3984 5.1875 10.1406 5.1875 9.8125V6.0625H1.4375C1.10938 6.0625 0.875 5.82812 0.875 5.52344C0.875 5.19531 1.10938 4.9375 1.4375 4.9375H5.1875V1.1875C5.1875 0.882812 5.42188 0.648438 5.75 0.648438C6.05469 0.648438 6.3125 0.882812 6.3125 1.1875V4.9375H10.0625C10.3672 4.9375 10.625 5.19531 10.625 5.5Z"
                            fill="#F7FAFF" />
                    </svg>
                </div>

                <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-class="transform scale-95 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-95 opacity-0">
                    <ul
                        v-if="viewOptions"
                        class="searchInput--options">
                        <li
                            v-if="!filter"
                            class="searchInput--option">
                            Enter your keyword
                        </li>

                        <li
                            v-if="filter"
                            class="searchInput--option"
                            @click="addKeyword(filter)">
                            Add "{{ filter }}" to keywords
                        </li>

                        <li
                            v-for="(option, i) in filteredOptions"
                            :key="i">
                            <label
                                :class="[
                                    'searchInput--option',
                                    isSelected(option) ? '--selected' : '',
                                ]">
                                {{ optionTextTransform(option) }}
                                <input
                                    v-model="selected"
                                    type="checkbox"
                                    class="sr-only"
                                    :value="option"
                                    @change="addKeyword" />
                            </label>
                        </li>
                    </ul>
                </transition>
            </div>
        </div>

        <div
            v-if="selected.length"
            class="flex gap-2 selected flex-wrap">
            <SearchTagInput
                v-for="(item, i) in selected"
                :key="i"
                :item="item"
                :selected="selected"
                :selected-text-transform="optionTextTransform"
                :selected-display-key="selectedDisplayKey"
                :type="searchType"
                @update:selected="updateSelected" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { debounce } from 'lodash'
import SearchTagInput from './SearchTagInput.vue'

export default {
    name: 'SearchKeywordInput',
    components: {
        SearchTagInput,
    },
    props: {
        id: {
            required: true,
            type: String,
        },
        value: {
            required: true,
            default: () => [],
            type: Array,
        },
        label: {
            required: true,
            type: String,
        },
        labelPlural: {
            required: true,
            type: String,
        },
        liveSearch: {
            required: false,
            default: false,
            type: Boolean,
        },
        placeholder: {
            required: false,
            type: String,
            default: null,
        },
        searchTip: {
            required: false,
            type: String,
            default: null,
        },
        searchType: {
            type: String,
            required: true,
        },
        selectedDisplayKey: {
            required: true,
            type: String,
        },
        optionTextTransform: {
            required: false,
            type: Function,
            default(option) {
                return option[this.selectedDisplayKey]
            },
        },
        showIcon: {
            required: false,
            default: true,
            type: Boolean,
        },
        inputClasses: {
            type: String,
            default:
                'text-sm rounded-md border-white bg-gray-darkest placeholder-white',
        },
    },
    data() {
        return {
            options: [],
            filter: null,
            internalValue: [],
            viewOptions: false,
            isLoading: false,
        }
    },
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(newSelected) {
                this.$emit('input', newSelected)
                this.hideOptions()
            },
        },
        filteredOptions() {
            let filtered = this.options

            if (this.filter) {
                return filtered.filter((option) => {
                    return option[this.filterBy]
                        .toLowerCase()
                        .includes(this.filter.toLowerCase())
                })
            }

            return filtered
        },
    },
    mounted() {
        this.internalValue = this.value
    },
    methods: {
        blockerListener(event) {
            if (event.code === 'Escape') {
                this.viewOptions = false
            }
        },
        showOptions() {
            this.viewOptions = true
            document.body.addEventListener('keyup', this.blockerListener)
            this.$refs.filterInput.classList.remove('hidden')
            this.$refs.filterInput.focus()
        },
        hideOptions() {
            this.viewOptions = false
            document.body.removeEventListener('keyup', this.blockerListener)
        },
        isSelected(option) {
            let filterBy = this.filterBy
            return this.selected.find(
                (selected) => selected[filterBy] === option[filterBy]
            )
        },
        addKeyword(keyword) {
            const selected = this.selected

            selected.push(keyword)

            this.selected = selected
            this.filter = null

            this.hideOptions()
        },
        updateSelected(newSelected) {
            this.selected = newSelected
        },
    },
}
</script>
