<template>
    <section class="assetSearchSelectedFilters">
        <div class="assetSearchSelectedFilters__header">
            <span
                class="assetSearchSelectedFilters__title">
                Current Selected Filter
            </span>
            <ButtonStripped
                href="/search/"
                text-size="text-sm"
                padding="px-0"
                class="font-bold hover:text-blue-darker">
                Clear All
            </ButtonStripped>
            <a
                v-if="isAdmin && recentSearchId"
                :href="`${endpoints.ADD_STORED_SEARCH}&recent_search_id=${recentSearchId}`"
                class="assetSearchSelectedFilters__link">
                Save Search <i class="fal fa-fw fa-plus plusIcon" />
            </a>
        </div>
    
        <div class="flex flex-col md:flex-row justify-between items-center gap-2">
            <div class="flex gap-2 flex-wrap">
                <ButtonOutline
                    v-for="brand in query.brands"
                    :key="brand.brand_id"
                    @click="removeArrayFilter('brands', brand)"
                    text-color="text-blue-darkest"
                    text-size="text-sm">
                    <span
                        v-text="brand.name"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </ButtonOutline> <!-- -->
        
                <ButtonOutline
                    v-for="property in query.properties"
                    :key="property.marsha_code"
                    text-color="text-blue-darkest"
                    @click="removeArrayFilter('properties', property)">
                    <span
                        class="mr-1"
                        v-text="property.marsha_code"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </ButtonOutline>
        
                <ButtonOutline
                    v-if="query.status.value === 'all'"
                    text-color="text-blue-darkest"
                    border-color="border-blue-light"
                    text-size="text-sm">
                    <span
                        v-text="query.status.name"
                    />
                </ButtonOutline>
                <ButtonOutline
                    v-else
                    text-color="text-blue-darkest"
                    text-size="text-sm"
                    @click="removeStatus()">
                    <span
                        class="mr-1"
                        v-text="query.status.name"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </ButtonOutline>
        
                <template v-if="query.asset_types.length">
                    <ButtonOutline
                        v-for="assetType in query.asset_types"
                        :key="assetType.asset_type_id"
    s                    text-color="text-blue-darkest"
                        text-size="text-sm"
                        @click="removeArrayFilter('asset_types', assetType)">
                        <span
                            class="mr-1"
                            v-text="assetType.name"
                        />
                        <i
                            v-if="assetType.asset_type_id !== 0"
                            class="far fa-fw fa-sm fa-times"
                        />
                    </ButtonOutline>
                </template>
                <template v-else>
                    <ButtonOutline
                        text-color="text-blue-darkest"
                        text-size="text-sm"
                        border-color="border-blue-light">
                        <span class="mr-1">All Asset Types</span>
                    </ButtonOutline>
                </template>
        
                <ButtonOutline
                    v-for="(keyword, i) in query.keywords"
                    :key="i"
                    text-color="text-blue-darkest"
                    text-size="text-sm"
                    @click="removeArrayFilter('keywords', keyword, false)">
                    <span
                        class="mr-1"
                        v-text="'Keyword: ' + keyword" />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </ButtonOutline>
        
                <button
                    v-for="division in query.divisions"
                    :key="division.division_id"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('divisions', division)">
                    <span
                        class="mr-1"
                        v-text="'Division: ' + division.name"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="region in query.regions"
                    :key="region.id"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('regions', region)">
                    <span
                        class="mr-1"
                        v-text="'Region: ' + region.name"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="state in query.states"
                    :key="state.state_id"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('states', state)">
                    <span
                        class="mr-1"
                        v-text="'State: ' + state.name"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="(city, i) in query.cities"
                    :key="i"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('cities', city, false)">
                    <span
                        class="mr-1"
                        v-text="'City: ' + city"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="group in query.groups"
                    :key="group.user_group_id"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('groups', group)">
                    <span
                        class="mr-1"
                        v-text="'Group: ' + group.name"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="subject in query.subjects"
                    :key="subject.subject_id"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('subjects', subject)">
                    <span
                        class="mr-1"
                        v-text="'Subject: ' + subject.name"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="collection in query.collections"
                    :key="collection.collection_id"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('collections', collection)">
                    <span
                        class="mr-1"
                        v-text="'Collection: ' + collection.name"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.date_created_start"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeStringFilter('date_created_start')">
                    <span
                        class="mr-1"
                        v-text="`Date Created Start: ${query.date_created_start}`"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.date_created_end"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeStringFilter('date_created_end')">
                    <span
                        class="mr-1"
                        v-text="`Date Created End: ${query.date_created_end}`"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.last_modified_start"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeStringFilter('last_modified_start')">
                    <span
                        class="mr-1"
                        v-text="`Last Updated Start: ${query.last_modified_start}`"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.last_modified_end"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeStringFilter('last_modified_end')">
                    <span
                        class="mr-1"
                        v-text="`Last Modified End: ${query.last_modified_end}`"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.from_mdam"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeBoolFilter('from_mdam')">
                    <span
                        class="mr-1"
                        v-text="'Imported from MDAM'"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="destination in query.destinations"
                    :key="destination.destination_id"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('destinations', destination)">
                    <span
                        class="mr-1"
                        v-text="'Destination: ' + destination.name"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="type in query.template_types"
                    :key="type.template_type_id"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('template_types', type)">
                    <span
                        class="mr-1"
                        v-text="'Template Type: ' + type.name"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="category in query.template_type_categories"
                    :key="category.template_type_category_id"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('template_categories', category)">
                    <span
                        class="mr-1"
                        v-text="'Template Category: ' + category.name"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="color in query.colors"
                    :key="color.color_id"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('colors', color)">
                    <span
                        class="mr-1"
                        v-text="'Color: ' + color.name"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.usage_rights"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeBoolFilter('usage_rights')">
                    <span
                        class="mr-1"
                        v-text="'Has Rights Documents'"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.is_stock"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeBoolFilter('is_stock')">
                    <span
                        class="mr-1"
                        v-text="'Is Stock'"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.is_offer"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeBoolFilter('is_offer')">
                    <span
                        class="mr-1"
                        v-text="'Is Offered'"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.hidden_from_search"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeBoolFilter('hidden_from_search')">
                    <span
                        class="mr-1"
                        v-text="'Hidden From Search'"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.property_only"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeBoolFilter('property_only')">
                    <span
                        class="mr-1"
                        v-text="'Property Only'"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.brand_only"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeBoolFilter('brand_only')">
                    <span
                        class="mr-1"
                        v-text="'Brand Only'"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.has_thumbnails"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeBoolFilter('has_thumbnails')">
                    <span
                        class="mr-1"
                        v-text="'Has Thumbnails'"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-if="query.from_dac"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeBoolFilter('from_dac')">
                    <span
                        class="mr-1"
                        v-text="'Imported from DAC'"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="(container, i) in query.containers"
                    :key="i"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('containers', container, false)">
                    <span
                        class="mr-1"
                        v-text="'Container: ' + container"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="(datapoint, i) in query.datapoints"
                    :key="i"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('datapoints', datapoint, false)">
                    <span
                        class="mr-1"
                        v-text="'Datapoint: ' + datapoint"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
        
                <button
                    v-for="(mdam_id, i) in query.mdam_ids"
                    :key="i"
                    class="pill --tag --blue --xs ml-1 mb-1"
                    @click="removeArrayFilter('mdam_ids', mdam_id, false)">
                    <span
                        class="mr-1"
                        v-text="'MDAM: ' + mdam_id"
                    />
                    <i
                        class="far fa-fw fa-sm fa-times"
                    />
                </button>
            </div>
            
            <list-format-toggle 
                :format="displayFormat" 
                @change="formatAs"
            />
        </div>
    </section>
</template>

<script>
import mutations from 'Stores/Frontend/mutation-types';
import { ADD_STORED_SEARCH } from 'Root/endpoints';
import { reject, pull } from 'lodash';
import ButtonStripped from '../Button/ButtonStripped.vue';
import ButtonOutline from '../Button/ButtonOutline.vue';
import ListFormatToggle from 'Components/Redesign/Asset/ListFormatToggle';
import { getParams } from 'vanilla-js-url';

const MUTATIONS_MAP = {
    // object array props
    'asset_types': mutations.SET_SEARCH_ASSET_TYPES,
    'brands': mutations.SET_SEARCH_BRANDS,
    'properties': mutations.SET_SEARCH_PROPERTIES,
    'regions': mutations.SET_SEARCH_REGIONS,
    'divisions': mutations.SET_SEARCH_DIVISIONS,
    'states': mutations.SET_SEARCH_STATES,
    'subjects': mutations.SET_SEARCH_SUBJECTS,
    'collections': mutations.SET_SEARCH_COLLECTIONS,
    'destinations': mutations.SET_SEARCH_DESTINATION_CONTROLS,
    'status': mutations.SET_SEARCH_STATUS,
    'template_categories': mutations.SET_SEARCH_TEMPLATE_TYPE_CATEGORIES,
    'template_types': mutations.SET_SEARCH_TEMPLATE_TYPES,
    'groups': mutations.SET_SEARCH_GROUPS,
    'mdam_ids': mutations.SET_SEARCH_MDAM_IDS,

    // value array props
    'keywords': mutations.SET_SEARCH_KEYWORDS,
    'cities': mutations.SET_SEARCH_CITIES,
    'containers': mutations.SET_SEARCH_CONTAINERS,
    'datapoints': mutations.SET_SEARCH_DATAPOINTS,

    // string props
    'date_created_start': mutations.SET_SEARCH_DATE_CREATED_START,
    'date_created_end': mutations.SET_SEARCH_DATE_CREATED_END,
    'last_modified_start': mutations.SET_SEARCH_LAST_MODIFIED_START,
    'last_modified_end': mutations.SET_SEARCH_LAST_MODIFIED_END,

    // boolean props
    'hidden_from_search': mutations.SET_SEARCH_HIDDEN_FROM_SEARCH,
    'usage_rights': mutations.SET_SEARCH_USAGE_RIGHTS,
    'is_stock': mutations.SET_SEARCH_IS_STOCK,
    'is_offer': mutations.SET_SEARCH_IS_OFFER,
    'from_mdam': mutations.SET_SEARCH_FROM_MDAM,
    'property_only': mutations.SET_SEARCH_PROPERTY_ONLY,
    'brand_only': mutations.SET_SEARCH_BRAND_ONLY,
    'has_thumbnails': mutations.SET_SEARCH_HAS_THUMBNAILS,
    'from_dac': mutations.SET_SEARCH_FROM_DAC,

    // list layout
    'display_format': mutations.SET_SEARCH_DISPLAY_FORMAT,
};

export default {
    components: {
        ButtonOutline,
        ButtonStripped,
        ListFormatToggle,
    },
    props: {
        isAdmin: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            endpoints: {
                ADD_STORED_SEARCH,
            },
        };
    },
    computed: {
        query: {
            get() {
                return this.$store.state.search.query;
            },
        },
        recentSearchId() {
            return this.$store.state.search.query.recent_search_id;
        },
        displayFormat() {
            return this.$store.getters.display;
        },
    },
    watch: {},
    mounted() {
        const searchQueryParams = getParams(window.location.search);

        if (searchQueryParams.hasOwnProperty('display')) {
            let display = searchQueryParams.display;

            if (display != this.displayFormat) {
                this.formatAs(display);
            }
        }
    },
    methods: {
        removeArrayFilter(filterKey, filter, isObject = true) {
            if (isObject) {
                this.$store.commit(MUTATIONS_MAP[filterKey], reject(this.query[filterKey], filter));
            } else {
                this.$store.commit(MUTATIONS_MAP[filterKey], pull(this.query[filterKey], filter));
            }
            this.$store.dispatch('search');
        },
        removeBoolFilter(filterKey) {
            this.$store.commit(MUTATIONS_MAP[filterKey], null);
            this.$store.dispatch('search');
        },
        removeStringFilter(filterKey) {
            this.$store.commit(MUTATIONS_MAP[filterKey], null);
            this.$store.dispatch('search');
        },
        removeStatus() {
            this.$store.commit(MUTATIONS_MAP['status'], {id: 0, name: 'All', value: 'all'});
            this.$store.dispatch('search');
        },
        formatAs(format) {
            this.$store.commit(MUTATIONS_MAP['display_format'], format);
        },
    }
};
</script>