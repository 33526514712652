<template>
    <div
        class="radioContainer--pill"
        :style="containerStyle">
        <label
            v-for="option in options"
            :key="option.value"
            :class="labelClasses">
            <input
                :id="name"
                :checked="value === option.value"
                @input="input"
                type="radio"
                :name="name"
                :class="inputClasses"
                :value="option.value" />
            <span :class="spanClasses(option.value)">
                <i
                    v-if="option.value === value && displayIcon"
                    class="far fa-check" />
                {{ option.label }}
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'RadioTab',
    props: {
        name: {
            required: false,
            type: String,
        },
        options: {
            required: true,
            type: Array,
        },
        value: {
            required: false,
            type: String,
        },
        displayIcon: {
            required: false,
            type: Boolean,
            default: true,
        },
        labelClass: {
            type: String,
            default: '',
        },
        inputClass: {
            type: String,
            default: '',
        },
        spanClass: {
            type: String,
            default: '',
        },
        checkedClass: {
            type: String,
            default: '',
        },
        backgroundColor: {
            type: String,
            default: '',
        },
    },
    computed: {
        containerStyle() {
            return this.backgroundColor
                ? { backgroundColor: this.backgroundColor }
                : {}
        },
        labelClasses() {
            return [this.labelClass || 'radio--pill cursor-pointer']
        },
        inputClasses() {
            return [this.inputClass || 'default-input-styles']
        },
        spanClasses() {
            return (value) => {
                // Apply checked class if selected
                return [
                    this.spanClass || 'flex gap-2.5 items-center whitespace-nowrpa',
                    value === this.value
                        ? this.checkedClass || 'default-checked-styles'
                        : '',
                ]
            }
        },
    },
    methods: {
        input(evt) {
            this.$emit('input', evt.target.value)
        },
    },
}
</script>
