/**
 * Define all vuex mutations in this file as constants. Don't forget to export them!
 *
 * Why? Seems annoying (It kind of is) but as noted in the vuex docs:
 * https://vuex.vuejs.org/guide/mutations.html#using-constants-for-mutation-types:
 *
 * > It is a commonly seen pattern to use constants for mutation types in various
 * > Flux implementations. This allows the code to take advantage of tooling like
 * > linters, and putting all constants in a single file allows your collaborators
 * > to get an at-a-glance view of what mutations are possible in the entire
 * > application
 */

export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_IS_LOCKED = 'SET_IS_LOCKED'
export const SET_SYSTEM_MESSAGES = 'SET_SYSTEM_MESSAGES'
export const SET_SYSTEM_MESSAGES_META = 'SET_SYSTEM_MESSAGES_META'
export const SET_MAIN_NAV_IS_OPEN = 'SET_MAIN_NAV_IS_OPEN'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_COLLECTION_ACTION = 'SET_COLLECTION_ACTION'
export const SET_SELECTED_ASSET = 'SET_SELECTED_ASSET'

// Search Stuff
export const SET_SEARCH_ASSETS = 'SET_SEARCH_ASSETS'
export const SET_SEARCH_ASSET_TYPES = 'SET_SEARCH_ASSET_TYPES'
export const SET_SEARCH_BRANDS = 'SET_SEARCH_BRANDS'
export const SET_SEARCH_BRAND_ONLY = 'SET_SEARCH_BRAND_ONLY'
export const SET_SEARCH_CITIES = 'SET_SEARCH_CITIES'
export const SET_SEARCH_COLLECTIONS = 'SET_SEARCH_COLLECTIONS'
export const SET_SEARCH_COLORS = 'SET_SEARCH_COLORS'
export const SET_SEARCH_CONTAINERS = 'SET_SEARCH_CONTAINERS'
export const SET_SEARCH_CREATED_BY = 'SET_SEARCH_CREATED_BY'
export const SET_SEARCH_DATAPOINTS = 'SET_SEARCH_DATAPOINTS'
export const SET_SEARCH_DATE_CREATED_END = 'SET_SEARCH_DATE_CREATED_END'
export const SET_SEARCH_DATE_CREATED_START = 'SET_SEARCH_DATE_CREATED_START'
export const SET_SEARCH_DESTINATIONS = 'SET_SEARCH_DESTINATIONS'
export const SET_SEARCH_DESTINATION_CONTROLS = 'SET_SEARCH_DESTINATION_CONTROLS'
export const SET_SEARCH_DIVISIONS = 'SET_SEARCH_DIVISIONS'
export const SET_SEARCH_EMBEDDED = 'SET_SEARCH_EMBEDDED'
export const SET_SEARCH_ELEVATED_IN_GALLERY = 'SET_SEARCH_ELEVATED_IN_GALLERY'
export const SET_SEARCH_FILE_TYPES = 'SET_SEARCH_FILE_TYPES'
export const SET_SEARCH_FROM_DAC = 'SET_SEARCH_FROM_DAC'
export const SET_SEARCH_FROM_MDAM = 'SET_SEARCH_FROM_MDAM'
export const SET_SEARCH_GROUPS = 'SET_SEARCH_GROUPS'
export const SET_SEARCH_HAS_SEARCHED = 'SET_SEARCH_HAS_SEARCHED'
export const SET_SEARCH_HAS_THUMBNAILS = 'SET_SEARCH_HAS_THUMBNAILS'
export const SET_SEARCH_HIDDEN_FROM_SEARCH = 'SET_SEARCH_HIDDEN_FROM_SEARCH'
export const SET_SEARCH_IS_LOADING = 'SET_SEARCH_IS_LOADING'
export const SET_SEARCH_IS_OFFER = 'SET_SEARCH_IS_OFFER'
export const SET_SEARCH_IS_STOCK = 'SET_SEARCH_IS_STOCK'
export const SET_SEARCH_KEYWORDS = 'SET_SEARCH_KEYWORDS'
export const SET_SEARCH_LAST_MODIFIED_END = 'SET_SEARCH_LAST_MODIFIED_END'
export const SET_SEARCH_LAST_MODIFIED_START = 'SET_SEARCH_LAST_MODIFIED_START'
export const SET_SEARCH_LIMITED_RIGHTS = 'SET_SEARCH_LIMITED_RIGHTS'
export const SET_SEARCH_MDAM_IDS = 'SET_SEARCH_MDAM_IDS'
export const SET_SEARCH_META = 'SET_SEARCH_META'
export const SET_SEARCH_NAME = 'SET_SEARCH_NAME'
export const SET_SEARCH_ORDER_BY = 'SET_SEARCH_ORDER_BY'
export const SET_SEARCH_PAGE = 'SET_SEARCH_PAGE'
export const SET_SEARCH_PER_PAGE = 'SET_SEARCH_PER_PAGE'
export const SET_SEARCH_PRIMARY_PROPERTY_IMAGE =
    'SET_SEARCH_PRIMARY_PROPERTY_IMAGE'
export const SET_SEARCH_PROPERTIES = 'SET_SEARCH_MARSHA_CODES'
export const SET_SEARCH_PROPERTY_IDS = 'SET_SEARCH_PROPERTY_IDS'
export const SET_SEARCH_PROPERTY_ONLY = 'SET_SEARCH_PROPERTY_ONLY'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const SET_SEARCH_RECENT_SEARCH_ID = 'SET_SEARCH_RECENT_SEARCH_ID'
export const SET_SEARCH_REGIONS = 'SET_SEARCH_REGIONS'
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
export const SET_SEARCH_RIGHTS_MANAGED = 'SET_SEARCH_RIGHTS_MANAGED'
export const SET_SEARCH_STATES = 'SET_SEARCH_STATES'
export const SET_SEARCH_STATES_PROVINCES = 'SET_SEARCH_STATES_PROVINCES'
export const SET_SEARCH_STATUS = 'SET_SEARCH_STATUS'
export const SET_SEARCH_SUBJECTS = 'SET_SEARCH_SUBJECTS'
export const SET_SEARCH_TEMPLATE_TYPES = 'SET_SEARCH_TEMPLATE_TYPES'
export const SET_SEARCH_TEMPLATE_TYPE_CATEGORIES =
    'SET_SEARCH_TEMPLATE_TYPE_CATEGORIES'
export const SET_SEARCH_TRAVEL_ADVISOR_ONLY = 'SET_SEARCH_TRAVEL_ADVISOR_ONLY'
export const SET_SEARCH_USAGE_RIGHTS = 'SET_SEARCH_USAGE_RIGHTS'

// User Stuff
export const SET_USER = 'SET_USER'
export const SET_USER_ASSOCIATIONS = 'SET_USER_ASSOCIATIONS'
export const SET_USER_GROUPS = 'SET_USER_GROUPS'
export const SET_USER_HAS_CHANGED = 'SET_USER_HAS_CHANGED'
export const SET_USER_IS_LOADING = 'SET_USER_IS_LOADING'
export const SET_USER_SELECTED_ASSETS = 'SET_USER_SELECTED_ASSETS'
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS'
export const SET_USER_VALID_AUTHORIZATION_LEVELS =
    'SET_USER_VALID_AUTHORIZATION_LEVELS'
export const SET_USER_VALID_STATUSES = 'SET_USER_VALID_STATUSES'
export const SET_USER_WORKSPACES = 'SET_USER_WORKSPACES'
export const SET_USER_WORK_ORDERS = 'SET_USER_WORKORDERS'

// User Favorites
export const SET_USER_FAVORITES = 'SET_USER_FAVORITES'
export const ADD_USER_FAVORITE = 'ADD_USER_FAVORITE'
export const REMOVE_USER_FAVORITE = 'REMOVE_USER_FAVORITE'
export const UPDATE_USER_FAVORITE = 'UPDATE_USER_FAVORITE'
export const BULK_UPDATE_USER_FAVORITES = 'BULK_UPDATE_USER_FAVORITES'
export const SET_USER_FAVORITES_IS_LOADING = 'SET_USER_FAVORITES_IS_LOADING'

// Asset Stuff
export const SET_ASSET = 'SET_ASSET'

// Available Statues
export const SET_AVAILABLE_STATUSES = 'SET_AVAILABLE_STATUSES'

// Remediations
export const SET_REMEDIATIONS_CONTACTS = 'SET_REMEDIATIONS_CONTACTS'
export const SET_REMEDIATIONS_NOTES = 'SET_REMEDIATIONS_NOTES'
export const SET_REMEDIATIONS_DOCUMENTS = 'SET_REMEDIATIONS_DOCUMENTS'
export const SET_REMEDIATIONS_ASSETS = 'SET_REMEDIATIONS_ASSETS'
export const SET_REMEDIATIONS_ERRORS = 'SET_REMEDIATIONS_ERRORS'
export const SET_REMEDIATIONS_PROPERTY_ID = 'SET_REMEDIATIONS_PROPERTY_ID'
export const SET_REMEDIATIONS_BRAND_ID = 'SET_REMEDIATIONS_BRAND_ID'
export const SET_REMEDIATIONS_SUBMITTED = 'SET_REMEDIATIONS_SUBMITTED'
export const SET_REMEDIATIONS_NOTIFY_SUBMITTER_BY_EMAIL =
    'SET_REMEDIATIONS_NOTIFY_SUBMITTER_BY_EMAIL'
export const SET_REMEDIATIONS_NOTIFY_SECONDARY_CONTACT_BY_EMAIL =
    'SET_REMEDIATIONS_NOTIFY_SECONDARY_CONTACT_BY_EMAIL'
export const SUBMIT_REMEDIATION = 'SUBMIT_REMEDIATION'

// Logo Uploads
export const SET_CSRF_TOKEN = 'SET_CSRF_TOKEN'
export const SET_LOGO_UPLOAD_FORM_DATA = 'SET_LOGO_UPLOAD_FORM_DATA'
export const SET_LOGO_UPLOAD_ASSET_STATUSES = 'SET_LOGO_UPLOAD_ASSET_STATUSES'
export const SET_LOGO_UPLOAD_BRANDS = 'SET_LOGO_UPLOAD_BRANDS'
export const SET_LOGO_UPLOAD_CAN_MANAGE_PERMISSIONS =
    'SET_LOGO_UPLOAD_CAN_MANAGE_PERMISSIONS'
export const SET_LOGO_UPLOAD_CONFIRMED = 'SET_LOGO_UPLOAD_CONFIRMED'
export const SET_LOGO_UPLOAD_COUNTRIES = 'SET_LOGO_UPLOAD_COUNTRIES'
export const SET_LOGO_UPLOAD_DESTINATIONS = 'SET_LOGO_UPLOAD_DESTINATIONS'
export const SET_LOGO_UPLOAD_DIVISIONS = 'SET_LOGO_UPLOAD_DIVISIONS'
export const SET_LOGO_UPLOAD_PROPERTIES = 'SET_LOGO_UPLOAD_PROPERTIES'
export const SET_LOGO_UPLOAD_PROPERTY_TYPES = 'SET_LOGO_UPLOAD_PROPERTY_TYPES'
export const SET_LOGO_UPLOAD_SOURCE_USER = 'SET_LOGO_UPLOAD_SOURCE_USER'
export const SET_LOGO_UPLOAD_SOURCE_TYPES = 'SET_LOGO_UPLOAD_SOURCE_TYPES'
export const SET_LOGO_UPLOAD_USER_GROUPS = 'SET_LOGO_UPLOAD_USER_GROUPS'
export const SET_LOGO_UPLOAD_USER_TYPES = 'SET_LOGO_UPLOAD_USER_TYPES'
export const SET_LOGO_UPLOAD_VENDOR_TYPES = 'SET_LOGO_UPLOAD_VENDOR_TYPES'
export const SET_LOGO_UPLOAD_ZIP = 'SET_LOGO_UPLOAD_ZIP'
export const SET_LOGO_UPLOAD_ERRORS = 'SET_LOGO_UPLOAD_ERRORS'
export const SUBMIT_LOGO_UPLOAD_ZIP = 'SUBMIT_LOGO_UPLOAD_ZIP'
export const SET_LOGO_UPLOAD_PARSED_FILES = 'SET_LOGO_UPLOAD_PARSED_FILES'
export const SET_LOGO_UPLOAD_ASSETS = 'SUBMIT_LOGO_UPLOAD_ASSETS'

// Notifications
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS'
export const SET_USER_NOTIFICATION_TO_UPDATE = 'SET_USER_NOTIFICATION_TO_UPDATE'

// Homepage Spotlight
export const SET_SPOTLIGHT_ASSET_NOTIFICATIONS =
    'SET_SPOTLIGHT_ASSET_NOTIFICATIONS'
export const SET_SPOTLIGHT_BRAND_ASSETS = 'SET_BRAND_ASSETS'
export const SET_SPOTLIGHT_FEATURED_NEWS = 'SET_SPOTLIGHT_FEATURED_NEWS'
export const SET_SPOTLIGHT_FEATURED_UPDATES = 'SET_SPOTLIGHT_FEATURED_UPDATES'
export const SET_SPOTLIGHT_IS_LOADING = 'SET_SPOTLIGHT_IS_LOADING'
export const SET_SPOTLIGHT_PROPERTY_ASSETS = 'SET_PROPERTY_ASSETS'
export const SET_SPOTLIGHT_UPDATES = 'SET_SPOTLIGHT_UPDATES'

// Contact Form
export const SET_CONTACT_FORM_CONTACTS = 'SET_CONTACT_FORM_CONTACTS'
export const SET_CONTACT_FORM_CONTENT = 'SET_CONTACT_FORM_CONTENT'
export const SET_CONTACT_FORM_HAS_BEEN_SENT = 'SET_CONTACT_FORM_HAS_BEEN_SENT'
export const SET_CONTACT_FORM_HEADING = 'SET_CONTACT_FORM_HEADING'
export const SET_CONTACT_FORM_IS_LOADING = 'SET_CONTACT_FORM_IS_LOADING'
export const SET_CONTACT_FORM_RESPONSE = 'SET_CONTACT_FORM_RESPONSE'
export const SET_CONTACT_FORM_TOPICS = 'SET_CONTACT_FORM_TOPICS'

// Options
export const SET_OPTIONS_APPROVAL_CATEGORIES = 'SET_OPTIONS_APPROVAL_CATEGORIES'
export const SET_OPTIONS_APPROVAL_DOCUMENT_TYPES =
    'SET_OPTIONS_APPROVAL_DOCUMENT_TYPES'
export const SET_OPTIONS_ASSET_TYPES = 'SET_OPTIONS_ASSET_TYPES'
export const SET_OPTIONS_BRANDS = 'SET_OPTIONS_BRANDS'
export const SET_OPTIONS_COUNTRIES = 'SET_OPTIONS_COUNTRIES'
export const SET_OPTIONS_DIVISIONS = 'SET_OPTIONS_DIVISIONS'
export const SET_OPTIONS_LANGUAGES = 'SET_OPTIONS_LANGUAGES'
export const SET_OPTIONS_REGIONS = 'SET_OPTIONS_REGIONS'
export const SET_OPTIONS_STATES = 'SET_OPTIONS_STATES'
export const SET_OPTIONS_TEMPLATE_TYPES = 'SET_OPTIONS_TEMPLATE_TYPES'
export const SET_OPTIONS_TEMPLATE_TYPE_CATEGORIES =
    'SET_OPTIONS_TEMPLATE_TYPE_CATEGORIES'
export const SET_OPTIONS_TIME_ZONES = 'SET_OPTIONS_TIME_ZONES'
export const SET_COLLATERAL_LINK_OPENED = 'SET_COLLATERAL_LINK_OPENED'

export default {
    SET_IS_LOADING,
    SET_IS_LOCKED,
    SET_SYSTEM_MESSAGES,
    SET_SYSTEM_MESSAGES_META,
    SET_MAIN_NAV_IS_OPEN,
    SET_NOTIFICATIONS,
    SET_COLLECTION_ACTION,
    SET_SELECTED_ASSET,

    // Search Stuff
    SET_SEARCH_ASSETS,
    SET_SEARCH_ASSET_TYPES,
    SET_SEARCH_BRANDS,
    SET_SEARCH_BRAND_ONLY,
    SET_SEARCH_CITIES,
    SET_SEARCH_CITIES,
    SET_SEARCH_COLLECTIONS,
    SET_SEARCH_COLORS,
    SET_SEARCH_CONTAINERS,
    SET_SEARCH_CREATED_BY,
    SET_SEARCH_DATAPOINTS,
    SET_SEARCH_DATE_CREATED_END,
    SET_SEARCH_DATE_CREATED_START,
    SET_SEARCH_DESTINATIONS,
    SET_SEARCH_DESTINATION_CONTROLS,
    SET_SEARCH_DIVISIONS,
    SET_SEARCH_ELEVATED_IN_GALLERY,
    SET_SEARCH_EMBEDDED,
    SET_SEARCH_FILE_TYPES,
    SET_SEARCH_FROM_DAC,
    SET_SEARCH_FROM_MDAM,
    SET_SEARCH_GROUPS,
    SET_SEARCH_HAS_SEARCHED,
    SET_SEARCH_HAS_THUMBNAILS,
    SET_SEARCH_HIDDEN_FROM_SEARCH,
    SET_SEARCH_IS_LOADING,
    SET_SEARCH_IS_OFFER,
    SET_SEARCH_IS_STOCK,
    SET_SEARCH_KEYWORDS,
    SET_SEARCH_LAST_MODIFIED_END,
    SET_SEARCH_LAST_MODIFIED_START,
    SET_SEARCH_LIMITED_RIGHTS,
    SET_SEARCH_MDAM_IDS,
    SET_SEARCH_META,
    SET_SEARCH_NAME,
    SET_SEARCH_ORDER_BY,
    SET_SEARCH_PAGE,
    SET_SEARCH_PER_PAGE,
    SET_SEARCH_PRIMARY_PROPERTY_IMAGE,
    SET_SEARCH_PROPERTIES,
    SET_SEARCH_PROPERTY_IDS,
    SET_SEARCH_PROPERTY_ONLY,
    SET_SEARCH_QUERY,
    SET_SEARCH_RECENT_SEARCH_ID,
    SET_SEARCH_REGIONS,
    SET_SEARCH_RESULTS,
    SET_SEARCH_RIGHTS_MANAGED,
    SET_SEARCH_STATES,
    SET_SEARCH_STATES_PROVINCES,
    SET_SEARCH_STATUS,
    SET_SEARCH_SUBJECTS,
    SET_SEARCH_TEMPLATE_TYPES,
    SET_SEARCH_TEMPLATE_TYPE_CATEGORIES,
    SET_SEARCH_TRAVEL_ADVISOR_ONLY,
    SET_SEARCH_USAGE_RIGHTS,

    // User Stuff
    SET_USER,
    SET_USER_ASSOCIATIONS,
    SET_USER_GROUPS,
    SET_USER_IS_LOADING,
    SET_USER_SELECTED_ASSETS,
    SET_USER_SETTINGS,
    SET_USER_VALID_AUTHORIZATION_LEVELS,
    SET_USER_VALID_STATUSES,
    SET_USER_WORKSPACES,
    SET_USER_WORK_ORDERS,
    SET_USER_HAS_CHANGED,

    // User Favorites
    ADD_USER_FAVORITE,
    BULK_UPDATE_USER_FAVORITES,
    REMOVE_USER_FAVORITE,
    SET_USER_FAVORITES,
    UPDATE_USER_FAVORITE,
    SET_USER_FAVORITES_IS_LOADING,

    // Asset Stuff
    SET_ASSET,

    // Available Statuses
    SET_AVAILABLE_STATUSES,

    // Remediations
    SET_REMEDIATIONS_CONTACTS,
    SET_REMEDIATIONS_NOTES,
    SET_REMEDIATIONS_DOCUMENTS,
    SET_REMEDIATIONS_ASSETS,
    SET_REMEDIATIONS_ERRORS,
    SET_REMEDIATIONS_PROPERTY_ID,
    SET_REMEDIATIONS_SUBMITTED,
    SET_REMEDIATIONS_NOTIFY_SUBMITTER_BY_EMAIL,
    SET_REMEDIATIONS_NOTIFY_SECONDARY_CONTACT_BY_EMAIL,
    SUBMIT_REMEDIATION,
    SET_REMEDIATIONS_BRAND_ID,

    // Logo Upload
    SET_CSRF_TOKEN,
    SET_LOGO_UPLOAD_ASSET_STATUSES,
    SET_LOGO_UPLOAD_BRANDS,
    SET_LOGO_UPLOAD_CAN_MANAGE_PERMISSIONS,
    SET_LOGO_UPLOAD_CONFIRMED,
    SET_LOGO_UPLOAD_COUNTRIES,
    SET_LOGO_UPLOAD_DESTINATIONS,
    SET_LOGO_UPLOAD_DIVISIONS,
    SET_LOGO_UPLOAD_FORM_DATA,
    SET_LOGO_UPLOAD_PROPERTIES,
    SET_LOGO_UPLOAD_PROPERTY_TYPES,
    SET_LOGO_UPLOAD_SOURCE_USER,
    SET_LOGO_UPLOAD_SOURCE_TYPES,
    SET_LOGO_UPLOAD_USER_GROUPS,
    SET_LOGO_UPLOAD_USER_TYPES,
    SET_LOGO_UPLOAD_VENDOR_TYPES,
    SET_LOGO_UPLOAD_ZIP,
    SET_LOGO_UPLOAD_ERRORS,
    SUBMIT_LOGO_UPLOAD_ZIP,
    SET_LOGO_UPLOAD_PARSED_FILES,
    SET_LOGO_UPLOAD_ASSETS,

    // Notifications
    SET_USER_NOTIFICATIONS,
    SET_USER_NOTIFICATION_TO_UPDATE,

    // Homepage Spotlight
    SET_SPOTLIGHT_ASSET_NOTIFICATIONS,
    SET_SPOTLIGHT_BRAND_ASSETS,
    SET_SPOTLIGHT_FEATURED_NEWS,
    SET_SPOTLIGHT_FEATURED_UPDATES,
    SET_SPOTLIGHT_IS_LOADING,
    SET_SPOTLIGHT_PROPERTY_ASSETS,
    SET_SPOTLIGHT_UPDATES,

    // Contact Form
    SET_CONTACT_FORM_CONTACTS,
    SET_CONTACT_FORM_CONTENT,
    SET_CONTACT_FORM_HAS_BEEN_SENT,
    SET_CONTACT_FORM_HEADING,
    SET_CONTACT_FORM_IS_LOADING,
    SET_CONTACT_FORM_RESPONSE,
    SET_CONTACT_FORM_TOPICS,

    // Options
    SET_OPTIONS_APPROVAL_CATEGORIES,
    SET_OPTIONS_APPROVAL_DOCUMENT_TYPES,
    SET_OPTIONS_ASSET_TYPES,
    SET_OPTIONS_BRANDS,
    SET_OPTIONS_COUNTRIES,
    SET_OPTIONS_DIVISIONS,
    SET_OPTIONS_LANGUAGES,
    SET_OPTIONS_REGIONS,
    SET_OPTIONS_STATES,
    SET_OPTIONS_TEMPLATE_TYPES,
    SET_OPTIONS_TEMPLATE_TYPE_CATEGORIES,
    SET_OPTIONS_TIME_ZONES,
    SET_COLLATERAL_LINK_OPENED,
}
