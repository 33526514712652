var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"p-4 flex gap-2"},_vm._l((_vm.links),function(link){return _c('li',{key:link.url,staticClass:"relative"},[_c('a',{class:[
                    'flex items-center gap-4 bg-white font-bold rounded-full shadow-sm p-4 cursor-pointer',
                    _vm.isActive(link)
                        ? 'text-blue'
                        : 'text-black hover:text-blue' ],attrs:{"href":link.url},on:{"mouseover":function($event){return _vm.setHoveredLink(link)},"mouseleave":_vm.clearHoverLink,"click":function($event){return _vm.handleClick($event, link)}}},[_c('i',{class:[
                        'text-gray',
                        'far',
                        'fa-fw',
                        ("fa-" + (link.icon)) ]})]),_vm._v(" "),(link.tooltip)?_c('BaseTooltip',{staticClass:"-translate-x-1/2 left-1/2",attrs:{"show-tooltip":_vm.isHovered(link),"message":link.tooltip}}):_vm._e()],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }